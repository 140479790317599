import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Typography,
  styled,
  Box,
  Drawer,
  useTheme,
  Popover,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { getChallDetail } from "../../api/lib/challenge";
import useRefMounted from "../../hooks/useRefMounted";
import { Countdown } from "../Clock";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import { useTranslation } from "react-i18next";
import { keyframes } from "@mui/system";
import { makeStyles } from "@mui/styles";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { WebsocketContext } from "../../contexts/WebSocketContext";
import { LoadingButton } from "@mui/lab";
import CopyToClipboardButton from "../CopyToClipboard";

const LabContainerWrapper = styled(Box)(
  ({ theme }) => `
      
       
      `
);

const LabComponent = ({
  laburl,
  is_visible,
  chall_id,
  titleShow = true,
  countDownShow = true,
}) => {
  const [isPulsing, setIsPulsing] = useState(true);

  const useStyles = makeStyles((theme) => ({
    btnRing: {
      minWidth: 80,
      minHeight: 30,

      background: `${theme.palette.info.main}`,
      color: `${theme.palette.info.contrastText}`,

      fontSize: 10,
      border: "none",
      borderRadius: 1000,
      transition: "all 0.3s ease-in-out 0s",
      cursor: "pointer",
      outline: "none",
      position: "relative",
      padding: 2,

      "&:hover, &:focus": {
        color: "#313133",
      },
      "&:hover::before, &:focus::before": {
        opacity: 1,
      },

      "&:after": {
        content: '""',
        width: 150,
        height: 150,
        borderRadius: "100%",
        border: "2px solid #00FFCB",
        position: "absolute",
        zIndex: -1,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        animation: isPulsing ? "$ring 1.5s infinite" : "none",
        display: isPulsing ? "block" : "none",
      },
      "&:hover::after, &:focus::after": {
        animation: "none",
        display: "none",
      },
    },
    "@keyframes ring": {
      "0%": {
        width: 30,
        height: 30,
        opacity: 1,
      },
      "100%": {
        width: 150,
        height: 150,
        opacity: 0,
      },
    },
  }));

  const theme = useTheme();
  const { t } = useTranslation();
  const [show, setShow] = useState(is_visible);
  const isMountedRef = useRefMounted();
  const [challenge, setChallenge] = useState(null);
  const classes = useStyles();
  const [isConnected, receive, send] = useContext(WebsocketContext);
  const [loadingRevert, setLoadingRevert] = useState(false);

  const navigate = useNavigate();
  const ref2 = useRef(null);
  const [isOpen2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const getChall = useCallback(async () => {
    const response = await getChallDetail(chall_id);

    if (isMountedRef.current) {
      setChallenge(response.data);
    }
  }, [chall_id, isMountedRef]);

  useEffect(() => {
    getChall();
  }, [getChall]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
    setIsPulsing(false);
  };

  const handleRevert = (event) => {
    //get target
    setLoadingRevert(true);
    send(JSON.stringify({ cmd: "VMRevert", id: chall_id }));
  };

  if (!challenge) {
    return null;
  }

  return (
    <>
      <Grid
        sx={{
          display: {
            xs: "none",
            lg: "flex",
            sm: "flex",
          },
        }}
        spacing={1}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
      >
        {!show && (
          <Helmet>ExtremeBox</Helmet>
        )}
        {show && (
          <>
           
            {titleShow && (
              <Grid item >
                <Chip
                 
                  variant="outlined"
                  label={challenge.title}
                  color="primary"
                  onClick={() => {
                    //console.log("challenge type labclick", challenge.chall_type);
                    {
                      challenge.chall_type === "challenge" &&
                        navigate(
                          `/dashboards/challenge/detail/${challenge.id}`
                        );
                    }
                    {
                      challenge.chall_type === "event" &&
                        navigate(
                          `/ctfevents/event/ctf/${challenge.eventid}/${challenge.categoryId}/${challenge.id}`
                        );
                    }
                    {
                      challenge.chall_type === "course" && (
                        () => {
                            //console.log("courseid Clicked", challenge.courseid.course_id);
                            navigate(
                                `/dashboards/lecture/${challenge.courseid.course_id}/${challenge.courseid.module_id}/${challenge.courseid.quiz_id}`
                            );
                        }
                    )();
                    }
                  }}
                ></Chip>
              </Grid>
            )}

            <Grid item>
         

              {laburl?.vms?.url && (
                <Chip
                  
                  color="success"
                  onClick={() => openInNewTab(laburl.vms.url)}
                  label={t("Access ExtremeBox here")}
                />
              )}
              
              {laburl?.vms?.IP && (
                <Toolbar title={t("Challenge IP")}>
                  <Typography variant="title" color="error"> 
                                {"IP: "} <CopyToClipboardButton textToCopy={laburl.vms?.IP} />
                  </Typography>
                </Toolbar>
              )}
            </Grid>

            {laburl.url && (
              <Grid item>
                <Chip
                  color="success"
                  //className={classes.btnRing}
                  onClick={() => openInNewTab(laburl.url)}
                  label={t("Access to Lab here")}
                ></Chip>
              </Grid>
            )}

            {challenge.content_type === "VMC" && (
              <Grid item>
                <Tooltip title={t("Revert the machine to prestine state")}>
                <LoadingButton
                  loading={loadingRevert}
                  color="error"
                  id="btn_revert"
                  variant="contained"
                  sx = {{
                    //button more thin
                    padding: 1,
                    
                  }}
                  onClick={()=>{handleRevert()}}
                  disabled={isConnected ? false : true}
                  startIcon={<AutorenewIcon />}
                 
                >{t("Revert")}</LoadingButton>
                </Tooltip>
              </Grid>
            )}
            
            {countDownShow && (
              <Grid item >
                <Typography>
                  {t("Expires")}
                  {": "}
                </Typography>
              </Grid>
            )}
            {countDownShow && laburl?.vms && (
              <Grid sx={{
                padding: 1
              }}>
                <Countdown
                  tgt={laburl.time_left * 1000}
                  showMoreTime={true}
                  chall_id={chall_id}
                ></Countdown>
              </Grid>
            )}

            {countDownShow && laburl.url && (
              <Grid item
              sx={{
                padding: 1
              }} >
                <Countdown
                  tgt={challenge.timesup * 1000}
                  showMoreTime={true}
                  chall_id={chall_id}
                ></Countdown>
              </Grid>
            )}
           
          </>
        )}
      </Grid>

      <Grid
        sx={{
          display: {
            xs: "flex",
            lg: "none",
            sm: "none",
          },
        }}
      >
        <Button
          ref={ref2}
          onClick={handleOpen2}
          endIcon={<KeyboardArrowDownTwoToneIcon />}
          variant="contained"
          size="small"
        >
          {t("Controls")}
        </Button>
      </Grid>

      <Popover
        disableScrollLock
        anchorEl={ref2.current}
        onClose={handleClose2}
        open={isOpen2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            background: theme.colors.gradients.blue3,
          },
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="baseline"
          spacing={2}
        >
          <Grid item>
            {titleShow && (
              <Button
                onClick={() => {
                  navigate(`/dashboards/challenge/detail/${challenge.id}`);
                }}
              >
                {challenge.title}
              </Button>
            )}
          </Grid>
          {/* <Grid item>
            {laburl?.vms?.url && (
              <Chip
                color="success"
                onClick={() => openInNewTab(laburl.vms.url)}
                label={t("ExtremeBox")}
              />
            )}
            {laburl?.vms?.IP && (
              <Toolbar title={t("Challenge IP")}>
                <Typography variant="title" color="error">
                  {"IP: "}
                  {laburl.vms.IP}
                </Typography>
              </Toolbar>
            )}
            {!laburl.url && (
              <Toolbar title={t("Reinicie o lab")}>
              <Typography variant="title" color="error">
                
                {t("Reinicie o lab")}
              </Typography>
            </Toolbar>
            )}
            {laburl.url && (
              <Button
                isPulsing={isPulsing}
                color="success"
                variant="contained"
                //className={classes.btnRing}
                onClick={() => openInNewTab(laburl.url)}
              >
                {t("Access to Lab here")}
              </Button>
            )}
            
          </Grid>
          {countDownShow && (
            <Grid item>
              <Typography color={"primary"}>{t("Expires: ")}</Typography>
            </Grid>
          )}
          <Grid item>
            {countDownShow && laburl?.vms && (
              <Grid item>
                <Countdown
                  tgt={laburl.time_left * 1000}
                  showMoreTime={true}
                  chall_id={chall_id}
                ></Countdown>
              </Grid>
            )}
          </Grid> */}

<Grid item>
         

         {laburl?.vms?.url && (
           <Chip
             
             color="success"
             onClick={() => openInNewTab(laburl.vms.url)}
             label={t("Access ExtremeBox here")}
           />
         )}
         
         {laburl?.vms?.IP && (
           <Toolbar title={t("Challenge IP")}>
             <Typography variant="title" color="error">
               {"IP: "}
               {laburl.vms?.IP}
             </Typography>
           </Toolbar>
         )}
       </Grid>

       {laburl.url && (
         <Grid item>
           <Chip
             color="success"
             //className={classes.btnRing}
             onClick={() => openInNewTab(laburl.url)}
             label={t("Access to Lab here")}
           ></Chip>
         </Grid>
       )}

       {challenge.content_type === "VMC" && (
         <Grid item>
           <Tooltip title={t("Revert the machine to prestine state")}>
           <LoadingButton
             loading={loadingRevert}
             color="error"
             id="btn_revert"
             variant="contained"
             onClick={()=>{handleRevert()}}
             disabled={isConnected ? false : true}
             startIcon={<AutorenewIcon />}
            
           >{t("Revert")}</LoadingButton>
           </Tooltip>
         </Grid>
       )}

       {countDownShow && (
         <Grid item>
           <Typography>
             {t("Expires")}
             {": "}
           </Typography>
         </Grid>
       )}
       {countDownShow && laburl?.vms && (
         <Grid item>
           <Countdown
             tgt={laburl.time_left * 1000}
             showMoreTime={true}
             chall_id={chall_id}
           ></Countdown>
         </Grid>
       )}

       {countDownShow && laburl.url && (
         <Grid item>
           <Countdown
             tgt={challenge.timesup * 1000}
             showMoreTime={true}
             chall_id={chall_id}
           ></Countdown>
         </Grid>
       )}


        </Grid>
      </Popover>
    </>
  );
};

export default LabComponent;
